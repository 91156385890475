import StringUtils from './util/string'
import {version} from '../package.json'

const env = process.env.REACT_APP_ENV || process.env.NODE_ENV

const config = {
    env,
    version,

    /**
        firebase console: settings/cloud messageing/Configuração da Web/Certificados push da Web
     */
    pushWebCert: 'BFO9hFGmc8jVCMpEWElny8bt0pbccOYmWgnLZYnSFWkxmRaHOPP9SihjUe7Z5wUNKwM7FFhy1Xt4kaqfZ_Tnsxo',

    firebase: {
        // apiKey: "AIzaSyDnNYKI9u1Bm1_Rg-oNsLLJSyzHbXMfw8A",
        // authDomain: "ninadigital.app",
        // databaseURL: "https://cobranca-b808f.firebaseio.com",
        // projectId: "cobranca-b808f",
        // storageBucket: "cobranca-b808f.appspot.com",
        // messagingSenderId: "1054979300239",
        // appId: "1:1054979300239:web:1155cad7d9dd93880009fa",
        // measurementId: "G-WBJZZSQXK1",
        //   //https://console.developers.google.com/apis/credentials?authuser=1&project=cobranca-b808f IDs do cliente OAuth 2.0-Web client
        // clientId: '1054979300239-3r1fjni95gopij4pk7tg2dul3crjgvdp.apps.googleusercontent.com'

        apiKey: "AIzaSyA1Hi0uZ9aVf1s-hMImphQrExlnzuaicpo",
        authDomain: "nina-yucs.firebaseapp.com",
        databaseURL: "https://nina-yucs-default-rtdb.firebaseio.com",
        projectId: "nina-yucs",
        storageBucket: "nina-yucs.appspot.com",
        messagingSenderId: "421522223023",
        appId: "1:421522223023:web:0029149a3f15c8500f095a"
    },

    sentry: {
        enable: env === "production",
        dsn: "https://23871c42c3c742078e1257a05408c3f5@o465715.ingest.sentry.io/5479046"
    },

    CHAT_DEFAULT_ICON: 'https://icons.iconarchive.com/icons/bokehlicia/captiva/256/chat-bubbles-icon.png',
    NINA_KEY: '&nina-bot',
    NINA_HUMAN_KEY: '&nina-human',
    NISI_URL:
        env == 'production'  ? 'https://api.nisidata.app' :
        env == 'development' ? 'http://localhost:5000' :
        'https://api-homol.nisidata.app',

    urlToken: StringUtils.urlParam(window.location, 'tk'),
    urlCpf: StringUtils.urlParam(window.location, 'cpf') || null
}

export default config
