// String.prototype.format = function(...args){
//     let str = this

//     args.forEach((p, i) => {
//         str = str.replace(`{${i}}`, p)
//     })

//     return str
// }

// String.prototype.capitalize = function(all = false) {
//     return all ?
//         this.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); }) :
//         this.charAt(0).toUpperCase() + this.substring(1)
// }

const stringutils = {
    urlParam(search, key){
        let values, index, result

        search = String(search)

        values = search.split('?')[1]
        if (!values) return undefined

        values = values.split('&')
        index = values
            .map((v,i)=>(v.split('=')[0]==key?i:null))
            .filter(v=>v!==null)[0]

        result = (values[index] || '').split('=')[1]

        return result         
    }
}

export default stringutils