import { useLayoutEffect, useRef } from "react"
import { createPortal } from "react-dom"

import csstransition from '../../util/csstransition'
import DOM from '../../util/dom'
import config from '../../config'

import './UIMenu.css'

export default function UIMenu({
    children,
    className,
    style=null,
    show=false,
    parent=null,
    transition=[`fade-in`, `menu-in-active`],
    transparent=false,
    autoClose=true,
    position="ll-bt",
    transitionShow=null, 
    transitionHide=null,
    anchor="left-bottom",
    onClose
}) {
    const menuEl = useRef()
    const overlayEl = useRef()
    const domRef = useRef()

    useLayoutEffect(() => {
        show ? showComponent() : hideComponent()
    // eslint-disable-next-line
    }, [show])

    function onClick(e) {
        if (autoClose && e.target.closest('[clickable]')) {
            hideComponent()
        }
    }

    function showComponent() {
        let menu = menuEl.current
        let overlay = overlayEl.current

        if (!menuEl.current.init) {
            menuEl.current.init = true
            complete()
        } else {
            setPosition()

            overlay.style.zIndex = config.zIndex()
            menu.style.zIndex = config.zIndex()

            csstransition(menu).run(transition, complete) 
        }

        function complete(){
            overlay.style.display = 'block'
        }
    }

    function hideComponent() {
        let menu = menuEl.current
        let overlay = overlayEl.current
        
        if (menu.style.display == 'none') {
            return
        }

        if (!menuEl.current.init) {
            menuEl.current.init = true
            complete()
        } else {
            csstransition(menu)
                .run('fade-out-active', complete)
        }

        function complete() {
            overlay.style.display = 'none'
            menu.style.display = 'none'

            onClose && onClose()
        }
    }

    function setPosition() {
        let menu = menuEl.current
        let parentEl = getParent()

        menu.style.display = ''

        DOM.positionByRect(menu, position, parentEl)
    }

    function getParent() {
        return parent ? parent.current : domRef.current.parentNode
    }

    return (
        <>
            <span ref={domRef} ui-menu="" />
            <MenuPortal
                className={className}
                style={style}
                overlayEl={overlayEl}
                menuEl={menuEl}
                transparent={transparent}
                onOverlayClick={hideComponent}
                onMenuClick={onClick}
            >
                {children}
            </MenuPortal>
        </>
    )
}

function MenuPortal({
    children,
    className="",
    style,
    overlayEl,
    menuEl,
    transparent,
    onOverlayClick,
    onMenuClick
}) {
    return createPortal(
        <>
            <div ref={overlayEl} className="ui-menu-overlay" onClick={onOverlayClick} />
            <div ref={menuEl}
                style={style}
                className={`ui-menu ${transparent?' ui-menu-transparent': ''} ${className}`}
                onClick={onMenuClick}
            >
                { children }
            </div>
        </>,

        document.body
    )
}