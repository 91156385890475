let textMetricsElement

const hpos = {
    adjust(x, ra, rb, mw) {
        if (x + rb.width > mw) x = ra.left
        if (x + rb.width > mw) x = mw - rb.width
        if (x < 0) x = 0

        return x
    },
    ll(ra, rb) {
        return ra.left
    },
    rl(ra, rb) {
        return ra.left + ra.width
    },
    lr(ra, rb) {
        return  ra.left - rb.width
    },
    rr(ra, rb) {
        return ra.left + ra.width - rb.width 
    },
    cc(ra, rb) {
        return ra.left + (ra.width / 2) - (rb.width / 2)
    },
    cl(ra, rb) {
        return ra.left + (ra.width / 2)
    },
    cr(ra, rb) {
        return ra.left + (ra.width / 2) - rb.width
    },
}

const vpos = {
    adjust(y, ra, rb, mr) {
        if (y + rb.height > mr) y = ra.top
        if (y + rb.height > mr) y = mr - rb.height
        if (y < 0) y = 0

        return y
    },
    tt(ra, rb) {
        return ra.top
    },
    bt(ra, rb) {
        return ra.top + ra.height
    },
    tb(ra, rb) {
        return  ra.top - rb.height
    },
    bb(ra, rb) {
        return ra.top + ra.height - rb.height 
    },
    cc(ra, rb) {
        return ra.top + (ra.height / 2) - (rb.height / 2)
    },
    ct(ra, rb) {
        return ra.top + (ra.height / 2)
    },
    cb(ra, rb) {
        return ra.top + (ra.height / 2) - rb.height
    },
}

const dom = {
    create(tagName, cssText, className) {
        let htmlElement, i, a, attrs;

        a = tagName.split("[");
        tagName = a[0];
        attrs = a[1];

        htmlElement = document.createElement(tagName);

        if (attrs) {
            a = attrs.replace("]", "").split(",");
            for (i = 0; i < a.length; i++) {
                attrs = a[i].split("=");
                htmlElement.setAttribute(attrs[0], attrs[1]);
            }
        }

        if (cssText) {
            htmlElement.style.cssText = cssText;
        }

        if (className) {
            htmlElement.className = className;
        }

        return htmlElement;
    },
    remove(htmlElement) {
        try {
            htmlElement.parentNode.removeChild(htmlElement);
        } catch (_e) {
        }
    },
    getStyle(o, property, camelProperty) {
        let val = null;

        if (o == null) {
            return null;
        }

        camelProperty = property;// this._hyphen2camel(property); //ex: line-width para lineWidth

        // Handle "float" property as a special case
        /*
            * if (property=="float") { val = jsf.Dom.getStyle(o,"cssFloat"); if
            * (val==null) { val = jsf.Dom.getStyle(o,"styleFloat"); } } else
            */
        if (o.currentStyle && o.currentStyle[camelProperty]) {
            val = o.currentStyle[camelProperty];
        } else if (window.getComputedStyle) {
            val = window.getComputedStyle(o, null).getPropertyValue(property);
        } else if (o.style && o.style[camelProperty]) {
            val = o.style[camelProperty];
        }
        // For color values, make the value consistent across browsers
        // Convert rgb() colors back to hex for consistency
        /*
            * if (/^\s*rgb\s*\(/.test(val)) { val = css.rgb2hex(val); } //
            * Lowercase all #hex values if (/^#/.test(val)) { val =
            * val.toLowerCase(); }
            */
        return val;
    },
    style(e, s) {
        let k, ss
        let rules = (e.getAttribute('style') || '').split(';').reduce((o,value)=>{
            let a = value.split(':')
            let k = a[0]
            let v = a[1]

            if (v) {
                o[k] = v
            }

            return o
        }, {})

        for (k in s) {
            rules[k] = s[k]
        }

        ss = Object.keys(rules).reduce((str, key)=>{
            return str + key + ':' + rules[key] + ';'
        }, '')

        e.setAttribute('style', ss)
    },

    /**
     * @param {HTMLElement|{left,top,height,width}} target Element to be positioned
     * @param {String} position format hh-vv, ex: ll-bt
     * @param {HTMLElement|{left,top,height,width}} ref Reference element
     * @param {Number|{left,top,bottom,right}} margin
    */
    positionByRect(target, position, ref=null) {
        let x, y, hcb, vcb, arr, styl, screen, rRef, rTarget
        let container = target.parentNode
        
        if (container.hasAttribute('is-template')) {
            container = container.parentNode
        }
        
        screen = {
            left: 0,
            top: 0,
            width: container.offsetWidth,
            height: container.offsetHeight
        }
        
        arr = position.split('-') //ex: ll-bt 
        hcb = hpos[arr[0]]
        vcb = vpos[arr[1]]

        if (!hcb || !vcb) {
            throw new Error('invalidate position, use format hh-vv, ex: ll-bt')
        }

        target.style.top = 0
        target.style.left = 0

        if (target.offsetWidth > screen.width - 80) {
            target.style.width = `${screen.width - 80}px`
        } else {
            target.style.width = `${target.offsetWidth}px`
        }

        rRef = ref ? (ref.getBoundingClientRect ? ref.getBoundingClientRect() : ref) : screen
        rTarget = target.getBoundingClientRect ? target.getBoundingClientRect() : target

        styl = window.getComputedStyle(target)
        rTarget.width += (parseInt(styl['marginLeft']) + parseInt(styl['marginRight']))
        rTarget.height += (parseInt(styl['marginTop']) + parseInt(styl['marginBottom']))

        x = hcb(rRef, rTarget)
        y = vcb(rRef, rTarget)
        x = hpos.adjust(x, rRef, rTarget, screen.width)
        y = vpos.adjust(y, rRef, rTarget, screen.height)
        
        this.style(target, {
            left: x + 'px',
            top: y + 'px'
        })
    },
    isChild(parent, child) {
        let i, p

        if (child.parentNode == parent) {
            return true
        }

        p = parent.childNodes;

        for (i = 0; i < p.length; i++) {
            if (this.isChild(p[i], child)) {
                return true
            }
        }

        return false
    },
    textMetrics(text, cssText, className) {
        if (!textMetricsElement) {
            textMetricsElement = document.body.appendChild(this.create('span', 'position:absolute; top:10000px; left:0;'));
        }

        textMetricsElement.style.cssText = cssText || "";
        textMetricsElement.className = className || "";
        textMetricsElement.innerHTML = text;

        return {
            width: textMetricsElement.offsetWidth,
            height: textMetricsElement.offsetHeight
        }
    }
}

export default dom
