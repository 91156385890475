import React, { lazy } from "react"
import { Route, Redirect } from "react-router-dom"

export const routes = [
    {
        exact: true,
        path: "/",
        component: lazy(() => import(/* webpackChunkName: "ChatView" */ "src/views/ChatView"))
    },
    {
        exact: true,
        path: "/humanchat",
        component: lazy(() => import(/* webpackChunkName: "ChatView" */ "src/views/ChatView"))
    },
    {
        exact: true,
        path: "/404",
        component: lazy(() => import(/* webpackChunkName: "Error404View" */ "src/views/Error404View"))
    },
    {
        exact: true,
        path: "/privacy",
        component: lazy(() => import(/* webpackChunkName: "PrivacyView" */ "src/views/PrivacyView"))
    },
    {
        exact: true,
        path: "/faq",
        component: lazy(() => import(/* webpackChunkName: "FaqView" */ "src/views/FaqView"))
    },
    {
        exact: true,
        path: "/donotcontact",
        component: lazy(() => import(/* webpackChunkName: "DoNotContactView" */ "src/views/DoNotContactView"))
    },
    {
        exact: true,
        path: "/simulation",
        component: lazy(() => import(/* webpackChunkName: "SimulationView" */ "src/views/SimulationView"))
    },
    {
        exact: true,
        path: "/confirmaddress",
        component: lazy(() => import(/* webpackChunkName: "AddressView" */ "src/views/AddressView"))
    },
    {
        component: () => <Redirect to="/404" />
    }
]

export function RouteRender(route) {
    return (
        <Route
            path={route.path}
            render={props => (
                <route.component {...props} routes={route.routes} />
            )}
        />
    )
}
