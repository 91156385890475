
export default function UIPreloader() {
    return (
        <div className="preloader">
            <span className="preloader-inner">
                <span className="preloader-inner-line"></span>
                <span className="preloader-inner-line"></span>
                <span className="preloader-inner-line"></span>
                <span className="preloader-inner-line"></span>
                <span className="preloader-inner-line"></span>
                <span className="preloader-inner-line"></span>
                <span className="preloader-inner-line"></span>
                <span className="preloader-inner-line"></span>
            </span>
        </div>
    )
}
